<template>
  <div id="app" class="tickets">
    <!-- Loading Spinner -->
    <div class="spinner" v-if="isLoading">
      <div><div></div></div>
    </div>
    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <div class="left">
        <a @click.prevent="goBackTo">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>我的票券</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <div class="pages">
        <a
          :class="['page', { active: statusFilter === 'pickedup' }]"
          @click.prevent="statusFilter = 'pickedup'"
        >
          <span>可使用</span>
        </a>
        <a
          :class="['page', { active: statusFilter === 'redeemed' }]"
          @click.prevent="statusFilter = 'redeemed'"
        >
          <span>已兌換</span>
        </a>
      </div>
      <div class="filter">
        <a class="dropdown" @click.prevent="categoryFilterPopOpen = true">
          <span v-if="categoryFilter">{{ categoryFilter.name }}</span>
          <span v-else>全部分類</span>
          <font-awesome-icon
            icon="fa-solid fa-caret-down"
            size="sm"
          ></font-awesome-icon>
        </a>
        <div class="search">
          <input type="text" placeholder="關鍵字搜尋" v-model="searchTerm" />
          <a
            ><font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              size="sm"
            ></font-awesome-icon
          ></a>
        </div>
      </div>
      <div v-if="initialized && showNoTicket" class="none">
        <img src="@/assets/icon/tkTaoyuan.svg" />
        <!-- mTicket.svg -->
        <span
          >目前尚無票券
          <fragment v-if="ecUrl"
            ><br />點擊下方「票券商城」<br />立即使用點數購買</fragment
          >
        </span>
      </div>
      <div v-else-if="initialized && !matchingTickets.length" class="gray">
        無符合的票券
      </div>
      <a
        v-else-if="initialized"
        class="coupon"
        v-for="(ticket, index) in matchingTickets"
        :key="'ticket_' + index"
        @click.prevent="$router.push(`/tickets/${ticket.ticketId}`)"
      >
        <div class="dot left"></div>
        <div class="dot right"></div>
        <div class="label" v-if="ticket.status === 'redeemed'">
          <span>已兌換</span>
        </div>
        <div class="content">
          <div>
            <span class="title">{{ ticket.title }}</span>
            <span class="name">{{ ticket.storeName }}</span>
          </div>
          <span class="info" v-html="ticket.description"></span>
          <span class="date">{{ tsToDatetime(ticket.displayedTs) }}</span>
        </div>
        <img v-if="ticket.image" :src="ticket.image" />
      </a>
      <a v-if="ecUrl" :href="ecUrl" target="_blank">
        <button class="action">
          <font-awesome-icon
            icon="fa-solid fa-store"
            size="lg"
          ></font-awesome-icon>
          <span>票券商城</span>
        </button>
      </a>
    </section>
    <!-- 分類popup -->
    <FilterPopup
      v-if="categoryFilterPopOpen"
      @closePopup="categoryFilterPopOpen = false"
      v-slot="pop"
    >
      <section class="popHeader">
        <span class="fL">店家分類</span>
      </section>
      <section class="popMain" @click.prevent="pop.close">
        <a
          @click.prevent="changeCategoryFilter(null)"
          :class="{ active: !categoryFilter }"
          ><span>全部分類</span></a
        >
        <a
          v-for="(category, index) in categories"
          :key="'category_' + index"
          :class="{
            active: categoryFilter && categoryFilter.name === category.name,
          }"
          @click.prevent="changeCategoryFilter(category)"
          ><span>{{ category.name }}</span></a
        >
      </section>
    </FilterPopup>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import FilterPopup from '@/components/FilterPopup.vue';

export default {
  name: 'Tickets',
  components: {
    FilterPopup,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      storeApiHost: process.env.VUE_APP_STORE_API_HOST,
      isLoading: false,
      originTickets: [],
      displayedTickets: [],
      searchTerm: '',
      categories: [],
      categoryFilter: null,
      statusFilter: 'pickedup',
      ecUrl: null,
      categoryFilterPopOpen: false,
      initialized: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === '/profile') {
      to.meta.parent = '/profile';
    } else if (from.path === '/') {
      to.meta.parent = localStorage.getItem('parentPath') || '/home';
    } else {
      to.meta.parent = '/home';
    }
    localStorage.setItem('parentPath', to.meta.parent);
    next();
  },
  mounted() {
    this.getTickets();
    this.getCategories();
    this.getEcUrl();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    matchingTickets() {
      const searchTerm = this.searchTerm.toLowerCase();
      return this.displayedTickets.filter(
        (ticket) =>
          ticket.status === this.statusFilter &&
          (ticket.storeName.toLowerCase().includes(searchTerm) ||
            ticket.title.toLowerCase().includes(searchTerm))
      );
    },
    showNoTicket() {
      if (!this.categoryFilter) {
        if (!this.displayedTickets.length) return true;
        if (this.statusFilter === 'pickedup') {
          const numberOfTickets = this.displayedTickets.filter(
            (ticket) => ticket.status === 'pickedup'
          ).length;
          if (!numberOfTickets) return true;
        }
      }
      return false;
    },
  },
  watch: {
    categoryFilter() {
      if (this.categoryFilter) {
        this.getTickets(this.categoryFilter.id);
      } else {
        this.getTickets();
      }
    },
  },
  methods: {
    goBackTo() {
      let parent = this.$route.meta.parent || '/home';
      this.$router.push(parent);
    },
    dummyTickets() {
      let result = [
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022131',
          productId: 1,
          name: '【50嵐】10元折價券（限桃園地區分店）',
          title: '【50嵐】10元折價券（限桃園地區分店）',
          description: '使用此券可折抵任一飲品10元',
          storeId: 7,
          storeName: 'GP健康定食創意料理店家',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761721,
          tickets: [
            {
              ticketId: 6,
              barcodeMessage: 'AAM654003488393',
              status: 'pickedup',
              updatedTs: 1664761722,
            },
            {
              ticketId: 7,
              barcodeMessage: 'AAM654003496576',
              status: 'pickedup',
              updatedTs: 1664761891,
            },
          ],
        },
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022132',
          productId: 2,
          name: '【yoxi】50元乘車金兌換券',
          title: '【yoxi】50元乘車金兌換券',
          description: '可使用此券兌換一組50元乘車金序號',
          storeId: 6,
          storeName: 'Miss Energy',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761891,
          tickets: [
            {
              ticketId: 8,
              barcodeMessage: 'AAMV552R817VZPR',
              status: 'redeemed',
              updatedTs: 1664761891,
            },
          ],
        },
      ];
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetTicketsAPI(categoryId) {
      let url = `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`;
      if (categoryId) url += `?storeCategoryId=${categoryId}`;
      var config = {
        method: 'get',
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    getTickets(categoryId = '') {
      // this.isLoading = true;
      this.callGetTicketsAPI(categoryId)
        // this.dummyTickets(categoryId)
        .then((res) => {
          console.log('getTicketsRes', res);
          this.originTickets = res.data;
          this.displayedTickets = res.data
            .reduce((acc, product) => {
              const tickets = product.tickets.map((ticket) => {
                const ticketCopy = { ...ticket };
                ticketCopy.title = product.title;
                ticketCopy.description = product.description;
                ticketCopy.storeName = product.storeName;
                ticketCopy.image = product.images[0];
                ticketCopy.storeCategory = product.storeCategory;
                ticketCopy.displayedTs = ticket.updatedTs || ticket.createdTs;
                return ticketCopy;
              });
              return (acc = [...acc, ...tickets]);
            }, [])
            .sort((a, b) => a.displayedTs - b.displayedTs);
          return;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.initialized = true));
    },
    callCategoriesAPI() {
      let config = {
        url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/categories`,
        method: 'get',
      };
      return this.$http(config);
    },
    getCategories() {
      this.callCategoriesAPI().then((res) => {
        this.categories = res.data;
      });
    },
    callGetEcUrlAPI() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/app-auth/ec`,
        method: 'get',
      };
      return this.$http(config);
    },
    getEcUrl() {
      this.callGetEcUrlAPI().then((res) => {
        this.ecUrl = res.data.authUrl;
      });
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm');
    },
    changeCategoryFilter(category) {
      this.categoryFilter = category;
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
